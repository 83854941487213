import React from 'react';
import { ApolloConsumer } from '@apollo/client';
import loadable from '@loadable/component';
import { Router, Location } from '@reach/router';
import Helmet from 'react-helmet-async';
import { timeout } from 'promise-timeout';
/**
 * Remove promiseMinDelay.js and delay.js after razzle babels node_modules
 * We can then use https://github.com/sindresorhus/p-min-delay again
 * */
import pMinDelay from 'core/promiseMinDelay';
import UserContextComponent, { UserContext } from 'context/UserContext';
import ToastContextComponent from 'context/ToastContext';

import Store from 'core/Store/Store';

import Sidebar from './Sidebar';
import ScrollToTop from './ScrollToTop';
import RoutePageLoader from './RoutePageLoader';
import RouteErrorBoundary from './RouteErrorBoundary';

const MIN_DELAY = 200;
const MAX_DELAY = 2000;

const loadBoundaries = mod => timeout(pMinDelay(mod, MIN_DELAY), MAX_DELAY);

const Login = loadable(() => loadBoundaries(import('features/login')));
const NotFound = loadable(() => loadBoundaries(import('routes/notFound')));
const BlocketExporter = loadable(() => loadBoundaries(import('routes/blocket-exporter')));
const DealerAPIDealerSearch = loadable(() => loadBoundaries(import('routes/dealer-search')));
const DealerAPI = loadable(() => loadBoundaries(import('routes/dealer-api')));
const DecodeJWT = loadable(() => loadBoundaries(import('routes/api-tokens/jwt-decode')));
const DealerPrivacy = loadable(() => loadBoundaries(import('routes/dealer-privacy/privacy')));
const Imports = loadable(() => loadBoundaries(import('routes/imports')));
const AdEvents = loadable(() => loadBoundaries(import('routes/ad-events')));
const ObjectHistory = loadable(() => loadBoundaries(import('routes/object-history')));
const ObjectData = loadable(() => loadBoundaries(import('routes/object-data')));
const FTPUsers = loadable(() => loadBoundaries(import('routes/ftp-users/list')));
const EditFTPUser = loadable(() => loadBoundaries(import('routes/ftp-users/edit')));
const Brands = loadable(() => loadBoundaries(import('routes/brands')));
const Brand = loadable(() => loadBoundaries(import('routes/brands/brand')));

const APIUsers = loadable(() => loadBoundaries(import('routes/api-tokens/list-api-users')));
const APITokens = loadable(() => loadBoundaries(import('routes/api-tokens/list-api-tokens')));
const APIToken = loadable(() => loadBoundaries(import('routes/api-tokens/create-api-token')));
const APIGroups = loadable(() => loadBoundaries(import('routes/api-tokens/list-api-groups')));
const CreateAPIGroup = loadable(() => loadBoundaries(import('routes/api-tokens/create-api-group')));
const EditAPIGroup = loadable(() => loadBoundaries(import('routes/api-tokens/edit-api-group')));

const CreateDealerGroup = loadable(() => loadBoundaries(import('routes/dealer-groups/create-dealer-group')));
const EditDealerGroup = loadable(() => loadBoundaries(import('routes/dealer-groups/edit-dealer-group')));
const DealerGroups = loadable(() => loadBoundaries(import('routes/dealer-groups/list-dealer-groups')));

const AdminAlert = loadable(() => loadBoundaries(import('routes/admin/admin-alert')));
const CreateUser = loadable(() => loadBoundaries(import('routes/admin/create-user')));
const PriceSigns = loadable(() => loadBoundaries(import('routes/admin/price-signs')));
const ChangePassword = loadable(() => loadBoundaries(import('routes/admin/change-password')));
const ProImportAPI = loadable(() => loadBoundaries(import('routes/pro-import-api')));

const Stocklist = loadable(() => loadBoundaries(import('routes/stocklist')));
const OfferEdit = loadable(() => loadBoundaries(import('routes/offer-edit')));
const OfferSearch = loadable(() => loadBoundaries(import('routes/offer-search')));
const Dealer = loadable(() => loadBoundaries(import('routes/dealer')));

const Users = loadable(() => loadBoundaries(import('routes/users')));
const AddUser = loadable(() => loadBoundaries(import('routes/users/add')));
const EditUser = loadable(() => loadBoundaries(import('routes/users/edit')));

const App = React.memo(({ authenticated, user }) => (
    <Store>
        <Helmet>
            <title>Toolbox</title>
        </Helmet>
        <ApolloConsumer>
            {client => (
                <UserContextComponent authenticated={authenticated} user={user} client={client}>
                    <ToastContextComponent>
                        <UserContext.Consumer>
                            {contextUser =>
                                contextUser.authenticated ? (
                                    <React.Fragment>
                                        <Sidebar />
                                        <Location>
                                            {({ location }) => (
                                                <React.Fragment>
                                                    <RouteErrorBoundary location={location}>
                                                        <Router className="main-container">
                                                            <NotFound default fallback={<RoutePageLoader show />} />
                                                            <DealerAPIDealerSearch
                                                                path="/"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <BlocketExporter
                                                                path="blocket-exporter"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <DealerAPI path="dealer/add" />
                                                            <DealerPrivacy
                                                                path="dealer/privacy"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <DecodeJWT
                                                                path="jwt-decode"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <Imports
                                                                path="imports/:dealerId"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <AdEvents
                                                                path="ad-events"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <ObjectData
                                                                path="object-data"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <ObjectHistory
                                                                path="object-history"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <FTPUsers
                                                                path="ftp-users"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <EditFTPUser
                                                                path="ftp-user/edit/:id"
                                                                fallback={<RoutePageLoader show />}
                                                            />

                                                            <Brands path="brands" fallback={<RoutePageLoader show />} />
                                                            <Brand
                                                                path="brands/:id"
                                                                fallback={<RoutePageLoader show />}
                                                            />

                                                            <APIUsers
                                                                path="api-users"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <APITokens
                                                                path="api-tokens/:username"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <APIToken
                                                                path="api-token/:username/create"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <APIGroups
                                                                path="api-groups"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <CreateAPIGroup
                                                                path="api-group/create"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <EditAPIGroup
                                                                path="api-group/edit/:name"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <DealerGroups
                                                                path="dealer-groups"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <CreateDealerGroup
                                                                path="dealer-group/create"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <EditDealerGroup
                                                                path="dealer-group/edit/:id"
                                                                fallback={<RoutePageLoader show />}
                                                            />

                                                            <AdminAlert
                                                                path="admin/admin-alert"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <CreateUser
                                                                path="admin/user/add"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <ChangePassword
                                                                path="admin/change-password"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <PriceSigns
                                                                path="admin/price-signs"
                                                                fallback={<RoutePageLoader show />}
                                                            />

                                                            <ProImportAPI
                                                                path="pro-import-api"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <Stocklist
                                                                path="stocklist/:id/:name"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <OfferEdit
                                                                path="offer/:id/:tab"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <OfferSearch
                                                                path="offer-search"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <Dealer
                                                                path="dealer/:id/:activeTab"
                                                                fallback={<RoutePageLoader show />}
                                                            />

                                                            <Users path="users" fallback={<RoutePageLoader show />} />
                                                            <EditUser
                                                                path="users/edit/:uuid"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                            <AddUser
                                                                path="users/add"
                                                                fallback={<RoutePageLoader show />}
                                                            />
                                                        </Router>
                                                    </RouteErrorBoundary>
                                                    <ScrollToTop location={location} />
                                                </React.Fragment>
                                            )}
                                        </Location>
                                    </React.Fragment>
                                ) : (
                                    <Login />
                                )
                            }
                        </UserContext.Consumer>
                    </ToastContextComponent>
                </UserContextComponent>
            )}
        </ApolloConsumer>
    </Store>
));

export default App;
