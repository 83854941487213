const getIsDev = () => {
    // Check if process.env.DOMAIN is available (Node.js)
    // Why not use NODE_ENV? Because it's faulty on the dev server and will be 'production'
    if (typeof process !== 'undefined' && process.env.DOMAIN === 'bytbil.io') {
        return true;
    }

    // Fallback to check `window` in the browser
    if (typeof window !== 'undefined' && window.App?.config?.IS_DEV) {
        return window.App.config.IS_DEV;
    }

    return false; // Assume production if not explicitly in development
};

const navigationLinks = [
    {
        id: 'dealer',
        icon: 'fa-home',
        text: 'Handlare',
        isHeading: true,
        subItems: [
            {
                id: 'dealer-search',
                text: 'Sök handlare',
                to: '/'
            },
            {
                id: 'dealer-groups',
                text: 'Handlargrupper',
                to: '/dealer-groups'
            },
            {
                id: 'dealer-privacy',
                text: 'Privacy',
                to: '/dealer/privacy'
            }
        ]
    },
    {
        id: 'ads',
        icon: 'fa-car',
        text: 'Annonser',
        isHeading: true,
        subItems: [
            {
                id: 'offer-search',
                text: 'Annonssök',
                to: '/offer-search'
            },
            {
                id: 'object-history',
                text: 'Objekthistorik',
                to: '/object-history'
            },
            {
                id: 'pro-import-api',
                text: 'Pro Import API',
                to: '/pro-import-api'
            }
        ]
    },
    {
        id: 'ftp',
        icon: 'fa-file',
        text: 'FTP',
        isHeading: true,
        subItems: [
            {
                id: 'ftp-users',
                text: 'Användare',
                to: '/ftp-users',
                isHeading: false
            }
        ]
    },
    {
        id: 'api',
        icon: 'fa-database',
        text: 'API',
        isHeading: true,
        subItems: [
            {
                id: 'api-users',
                text: 'Användare',
                to: '/api-users'
            },
            {
                id: 'api-groups',
                text: 'Grupper',
                to: '/api-groups'
            },
            {
                id: 'jwt-decode',
                text: 'Decode JWT-token',
                to: '/jwt-decode'
            }
        ]
    },
    {
        id: 'vehicle-info',
        icon: 'fa-info',
        text: 'Fordon',
        isHeading: true,
        subItems: [
            {
                id: 'object-data',
                text: 'Transportstyrelsen',
                to: '/object-data'
            },
            {
                id: 'brands',
                text: 'Märken & modeller',
                to: '/brands'
            }
        ]
    },

    {
        id: 'admin',
        icon: 'fa-info',
        text: 'Blocket Admin',
        isHeading: true,
        subItems: [
            {
                id: 'users',
                text: 'Användare',
                to: '/users'
            },
            {
                id: 'admin-alert',
                text: 'Admin Alert',
                to: '/admin/admin-alert'
            },
            {
                id: 'price-signs',
                text: 'Prisskyltar',
                to: '/admin/price-signs'
            }
        ]
    },
    {
        id: 'links',
        icon: 'fa-external-link',
        text: 'Länkar',
        isHeading: true,
        subItems: [
            {
                id: 'finance-admin',
                text: 'Finansadmin',
                icon: 'fa-external-link',
                to: getIsDev()
                    ? 'https://finans-dev.bytbil.io/admin_finans.cgi'
                    : 'https://finans.bytbil.com/admin_finans.cgi',
                external: true
            }
        ]
    },
    {
        id: 'account',
        icon: 'fa-user',
        to: 'account',
        text: 'Ditt konto',
        isHeading: true,
        hideInSidebar: true,
        subItems: [
            {
                id: 'logout',
                to: '#logout',
                text: 'Logga ut'
            }
        ]
    }
];

export default navigationLinks;
