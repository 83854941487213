import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { ApolloClient } from '@apollo/client';

import logoutMutation from './mutations/logout';

export const UserContext = createContext();

const UserContextComponent = ({ children, client, authenticated: initialAuthenticated, user }) => {
    // Manage authenticated state
    const [authenticated, setAuthenticated] = useState(initialAuthenticated);

    // Logout function
    const logout = async () => {
        // Set authenticated to false
        setAuthenticated(false);

        // Execute GraphQL mutation for logout
        await client.mutate({
            mutation: logoutMutation
        });
    };

    // Prepare context value with state and actions
    const contextValue = {
        authenticated,
        actions: {
            logout
        },
        user
    };

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

// PropTypes validation
UserContextComponent.propTypes = {
    children: PropTypes.node.isRequired,
    client: PropTypes.instanceOf(ApolloClient).isRequired,
    authenticated: PropTypes.bool.isRequired
};

export default UserContextComponent;
