import './scss/style.scss';
import 'core-js/features/array/from';
import 'core-js/features/array/find-index';
import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/object/assign';
import 'core-js/features/object/keys';
import 'core-js/features/promise';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';
import { HelmetProvider } from 'react-helmet-async';
import * as sentry from '@sentry/browser';
import { ApolloProvider } from '@apollo/client';
import createApolloClient from 'core/createApolloClient';
import App from 'components/App';
import createLogger from 'core/createLogger';

if (typeof window.location.origin === 'undefined') {
    window.location.origin = `${window.location.protocol}//${window.location.host}`;
}

const apolloClient = createApolloClient();

let logger;
if (typeof window.App.config.sentry !== 'undefined') {
    logger = createLogger(
        sentry,
        { dsn: window.App.config.sentry.dsn, environment: window.App.config.sentry.environment },
        window.App.config.sentry.optional
    );

    // Unhandled promise rejections are not captured by raven
    window.onunhandledrejection = data => {
        logger.captureException(data.reason);
    };

    window.addEventListener('unhandledrejection', err => {
        const { reason } = err;
        if (err instanceof Error) {
            logger.captureException(reason);
        } else if (Object.prototype.toString.call(reason) === '[object String]') {
            logger.captureMessage(reason);
        }
    });
}

// Fixes :active state on IOS devices
// https://developers.google.com/web/fundamentals/design-and-ux/input/touch/
window.onload = () => {
    if (/iP(hone|ad)/.test(window.navigator.userAgent)) {
        document.body.addEventListener('touchstart', () => {}, false);
    }
};

const container = document.getElementById('root');

const AppWrapper = () => {
    let user;
    let authenticated = false;

    if (window.App && window.App.user && window.App.user.authenticated !== undefined) {
        ({ user } = window.App);
        ({ authenticated } = user);
    } else {
        console.warn('App or user authentication is missing');
    }

    return (
        <HelmetProvider>
            <ApolloProvider client={apolloClient}>
                <App authenticated={authenticated} user={user} />
            </ApolloProvider>
        </HelmetProvider>
    );
};

// Hydrate the app using the common component
loadableReady(() => {
    ReactDOM.hydrate(<AppWrapper apolloClient={apolloClient} container={container} />, container);
});

if (module.hot) {
    module.hot.accept();
}
